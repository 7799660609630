import { Button, GiftCard, GiftCardProps, ModalProps, SVG } from '@inkd/ui';
import { MouseEvent } from 'react';

export type GiftCardSelectorModalProps = Omit<
  ModalProps,
  'headingText' | 'handleConfirm'
> & {
  giftCards: GiftCardProps[];
  handleCancel: (clickEvent: MouseEvent<HTMLButtonElement>) => void;
  cancelButtonText: string;
};

export const GiftCardSelectorModal = ({
  cancelButtonText,
  handleCancel,
  giftCards,
  ...dialogProps
}: GiftCardSelectorModalProps) => {
  return (
    <>
      <dialog
        className={
          'text-primary animate-scaleTo100 absolute top-0 z-30 min-h-screen w-full max-w-[100vw] bg-transparent p-16'
        }
        {...dialogProps}
      >
        <div
          // full-screen element used to display the gradient background
          className={
            'bg-gradient-blue-to-purple absolute left-0 top-0 h-full w-full opacity-90'
          }
        />
        <div className='bg-surface-primary relative z-40 flex h-full w-full flex-col items-center justify-center gap-16 rounded-lg p-16'>
          <button
            className='text-primary inset-ie-8 inset-bs-8 absolute'
            onClick={handleCancel}
          >
            <span className='sr-only'>Close modal</span>
            <SVG name='X' className='h-12 w-12' />
          </button>
          <div className='flex flex-col gap-0'>
            <p className='text-center text-[1.25rem] uppercase'>
              Add a gift card
            </p>
            <h2 className='text-heading1 text-center font-light'>
              Select a Gift Card
            </h2>
          </div>
          <div className='z-20 grid w-full grid-cols-3 gap-8'>
            {giftCards.map(giftCard => (
              <GiftCard {...giftCard} key={giftCard.providerName} />
            ))}
          </div>
          <div className='flex w-full items-center justify-between gap-x-4'>
            <Button
              variant='secondary'
              onClick={handleCancel}
              className='self-start'
            >
              {cancelButtonText}
            </Button>
          </div>
        </div>
      </dialog>
    </>
  );
};
