import type { ComponentPropsWithoutRef } from 'react';
import type { GeneratedSVGNames } from '@inkd/svgs';
import { KIOSK_ASSET_BASE_PATH } from '@inkd/utils';

interface SVGProps extends ComponentPropsWithoutRef<'svg'> {
  name: GeneratedSVGNames;
}

/**
 * A wrapper component that allows us to access SVGs in our project from the related sprite sheet
 * that is added to the public folder of all of our apps
 */
export function SVG({ className, name, ...svgProps }: SVGProps) {
  return (
    <svg
      className={className ?? 'h-6 w-6 text-inherit'}
      style={{ fill: 'currentColor' }}
      {...svgProps}
    >
      <use
        href={`${
          /**
           * Because of the differences between the actual kiosk deployment and
           * the Vercel deployment, we need the option to dynamically configure
           * a "base path" for the sprite sheet href
           *
           * For the kiosk, all of the local assets are nested within a folder
           * named (at time of writing) `/custom2`.
           * In Vercel, however, the assets are in the root folder, so no base
           * path is specified.
           */
          typeof document !== 'undefined' && // this ensures we're _not_ in a server environment
          document.documentElement.dataset.renderingOn === 'kiosk' // this ensures we're on the kiosk (as opposed to the web)
            ? KIOSK_ASSET_BASE_PATH
            : ''
        }/icons/spritesheet.svg#inkd-svg__${name}`}
      />
    </svg>
  );
}
