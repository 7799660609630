import type { CSSProperties, JSX, MouseEventHandler } from 'react';
import { createPortal } from 'react-dom';
import { useModal } from '@inkd/ui';
import { type SelectedCardDetails } from '@inkd/xstate';
import { CARD_PRICE } from '@inkd/utils';

export interface SelectCardModalProps {
  handleCancel: MouseEventHandler<HTMLButtonElement>;
  handleConfirm: MouseEventHandler<HTMLButtonElement>;
  modalOpen: boolean;
  selectedCard: SelectedCardDetails | null;
  /** Pass a component that will render an image. use an <img /> tag on the kiosk app and use the Next.js image component on the web/mobile app */
  mainImageRenderer: (props: {
    src: string;
    alt: string;
    className: string;
    style?: Record<string, string>;
  }) => JSX.Element;
  /** Pass a component that will render an image. use an <img /> tag on the kiosk app and use the Next.js image component on the web/mobile app */
  interiorImageRenderer: (props: {
    src: string;
    alt: string;
    className: string;
    style?: CSSProperties;
  }) => JSX.Element;
}

/**
 * The modal component that renders a card that the user wants to select and personalize. If
 * the user confirms their selection, it transitions them to the personalization flow, if
 * the user cancels their selection, it transitions them back to the previous state in the
 * browsing flow.
 */
export function SelectCardModal({
  handleCancel,
  handleConfirm,
  modalOpen,
  selectedCard,
  mainImageRenderer,
  interiorImageRenderer,
}: SelectCardModalProps) {
  const { Modal } = useModal();

  return createPortal(
    <div id='inkd-select-card-modal-wrapper'>
      <div
        data-open={modalOpen}
        className='bg-gradient-blue-to-yellow fixed inset-0 z-50 opacity-90 data-[open=false]:hidden'
      />
      <Modal
        id='inkd-select-card-modal'
        className='web:rounded-none web:sm:rounded-md fixed inset-0 z-50 w-full max-w-[640px] justify-items-center'
        headingText='Choose this card?'
        handleCancel={e => {
          handleCancel(e);
        }}
        handleConfirm={e => {
          e.preventDefault();
          handleConfirm(e);
        }}
        open={modalOpen}
      >
        <div
          className='kiosk:p-be-16 kiosk:-m-i-10 kiosk:w-[80vw] web:p-be-6 grid h-full w-full grid-cols-[repeat(12,1fr)] grid-rows-[1fr_auto_1fr]'
          data-cy='Card Image'
        >
          <div className='relative z-[1] col-start-1 col-end-7 row-start-1 row-end-4 aspect-[5/7] h-full'>
            {selectedCard?.previewMainPhotoUrl
              ? mainImageRenderer({
                  src: selectedCard.previewMainPhotoUrl,
                  alt:
                    selectedCard.mainImageDescription ?? 'cover image preview',
                  className:
                    'bg-white p-[3%] border h-full w-full border-black/10 object-fill object-center shadow-[0px_80px_80px_-80px_rgba(0,0,0,0.20),_0px_2px_4px_0px_rgba(0,0,0,0.10)]',
                })
              : null}
          </div>
          <div
            className='col-start-2 col-end-13 row-start-2 row-end-3 aspect-[10/7] border border-black/10 shadow-[0px_80px_80px_-80px_rgba(0,0,0,0.20),_0px_2px_4px_0px_rgba(0,0,0,0.10)]'
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)',
              gridTemplateRows: '1fr',
            }}
          >
            <div
              className='border-r border-black/10'
              style={{ gridArea: '1/1/2/2' }}
            />

            {selectedCard?.previewInteriorPhotoUrl
              ? interiorImageRenderer({
                  src: selectedCard.previewInteriorPhotoUrl,
                  alt: 'interior image preview',
                  className: 'p-[3%] h-full w-full object-cover',
                  style: { gridArea: '1/2/2/3' },
                })
              : null}
          </div>
        </div>
        <hr className='m-be-4 text-tonal-30 m-i-auto w-32 border-2 border-current' />
        <h2
          className='text-heading2 text-center font-semibold'
          data-cy='Card Price'
        >
          {CARD_PRICE}
        </h2>
      </Modal>
    </div>,
    document.body
  );
}
