import { cx } from 'cva';
import { ComponentPropsWithRef, ComponentPropsWithoutRef } from 'react';

interface BaseGiftCardProps {
  providerName: string;
  imgSrc: string;
}
interface DivGiftCard extends BaseGiftCardProps {
  className?: ComponentPropsWithRef<'div'>['className'];
  onClick?: never;
}

interface ButtonGiftCard extends BaseGiftCardProps {
  className?: ComponentPropsWithRef<'button'>['className'];
  /** if onClick is provided, the GiftCard will be rendered as a button; else a div */
  onClick: ComponentPropsWithoutRef<'button'>['onClick'];
}

export type GiftCardProps = ButtonGiftCard | DivGiftCard;

/**
 * This component can be used for both clickable and static UI
 *
 * If an onClick function is provided, a `button` will be rendered.
 *
 * In the absence of an onClick function, then a `div` will be rendered.
 */
export function GiftCard({
  providerName,
  imgSrc,
  onClick,
  className,
  ...elementProps
}: GiftCardProps) {
  return onClick ? (
    <button
      {...elementProps}
      onClick={onClick}
      aria-label={`Select ${providerName} gift card`}
      className={cx(
        className,
        'flex aspect-[8/5] max-w-[242px] items-center justify-center rounded-[9px]'
      )}
    >
      <img src={imgSrc} alt={providerName} />
    </button>
  ) : (
    <div
      {...elementProps}
      className={cx(
        className,
        'flex aspect-[8/5] max-w-[242px] items-center justify-center rounded-[4.5px]'
      )}
    >
      <img src={imgSrc} alt={providerName} />
    </div>
  );
}
