import type { NextLink } from '@inkd/web/src/components/NextTypes';
import type { CSSProperties, JSX, MouseEventHandler } from 'react';
import { Button, ButtonAsLink } from '@inkd/ui';
import { cx } from 'cva';
import { type Card } from '@inkd/graphql';
import { type SelectedCardDetails } from '@inkd/xstate';

interface BaseCardCarouselProps {
  /** The height to be used for the card images and for the last card which is either a link or button to the relevant category page */
  cardHeight: number;
  /** The width to be used for the card images and for the last card which is either a link or button to the relevant category page */
  cardWidth: number;
  /** the list of cards to render */
  cards: (Card | null)[];
  /**
   * the category that this carousel of cards is mapped to. If not provided, then we won't render
   * the <h2> tag with the text
   */
  categoryName: string;
  className?: string;
  /**
   * Callback function to handle when a card in the carousel is clicked.
   *
   * @param selectCardModalContext The required values that will be passed to XState's context when
   * you trigger the send() call to the SELECT_CARD event in the browsingFlow
   */
  handleClick: (selectedCard: SelectedCardDetails) => void;
  /** Pass a component that will render an image. use an <img /> tag on the kiosk app and use the Next.js image component on the web/mobile app */
  imageRenderer: (props: {
    src: string;
    alt: string;
    className: string;
    width: number;
    height: number;
    style: CSSProperties;
  }) => JSX.Element;
}

interface WebCardCarouselProps extends BaseCardCarouselProps {
  handleCategoryClick?: never;
  /** The URL/route to be attached to the category heading and the last card in the carousel so that you can navigate to that category's route */
  href: string;
  /** Pass the Next.js `<Link />` component here for use on the mobile site */
  NextLink: NextLink;
}
interface KioskCardCarouselProps extends BaseCardCarouselProps {
  /** The onClick handler to be attached to the category heading and the last card in the carousel so that you can navigate to that category's route */
  handleCategoryClick: MouseEventHandler<HTMLButtonElement>;
  href?: never;
  NextLink?: never;
}

export type CardCarouselProps = WebCardCarouselProps | KioskCardCarouselProps;

/**
 * A reusable carousel component that uses native CSS properties to create a touch-friendly
 * experience for showing off the greeting cards of a specific category.
 *
 * The kiosk app variant expects a `handleCategoryClick` event handler to be passed to the
 * heading for the category and the last card in the carousel so that XState can transition
 * the user to that specific category's route via click handlers on `<button>` tags, since
 * XState needs to be the driver of the routing.
 *
 * The mobile/web app variant expects the relevant category's `href` and a reference to the
 * Next.js `<Link />` component given to the `NextLink` prop, so that the browser can handle
 * navigating the user to the appropriate category route via standard anchor `<a>` tags
 * instead of `<button>`s on the Kiosk.
 */
export function CardCarousel({
  cards,
  cardHeight,
  cardWidth,
  categoryName,
  className,
  handleCategoryClick,
  handleClick,
  href,
  imageRenderer,
  NextLink,
}: CardCarouselProps) {
  return (
    <div
      className={cx(
        'p-b-20 p-is-16 web:p-b-10 web:p-is-3 [&:nth-of-type(even)]:bg-surface-secondary relative bg-white',
        className
      )}
    >
      {handleCategoryClick ? (
        <button
          className='border-tonal-30 text-tonal-60 -inset-bs-10 inset-is-16 web:-inset-bs-5 web:inset-is-3 p-i-8 p-b-6 web:p-i-4 web:p-b-1 text-heading3 kiosk:z-10 kiosk:leading-none absolute w-max rounded-xl border bg-white font-semibold'
          onClick={handleCategoryClick}
        >
          {categoryName}
        </button>
      ) : (
        <NextLink
          href={href}
          className='border-tonal-30 text-tonal-60 -inset-bs-10 inset-is-16 web:-inset-bs-5 web:inset-is-3 p-i-8 p-b-6 web:p-i-4 web:p-b-1 text-heading3 kiosk:z-10 kiosk:leading-none absolute w-max rounded-xl border bg-white font-semibold'
        >
          {categoryName}
        </NextLink>
      )}
      <div className='hide-scrollbar scroll-p-i-12 snap-inline web:scroll-p-i-8 ios-overflow-scrolling-touch web:gap-6 grid snap-mandatory auto-cols-max grid-flow-col gap-12 overflow-x-scroll'>
        {cards.map(card => {
          if (!card || !card.images || !card.isActive) {
            return null;
          }
          const selectedCard = {
            cardId: card.id,
            cardName: card.cardName,
            previewMainPhotoUrl: card.images.previewMainPhotoUrl,
            mainImageDescription: card.images.mainImageDescription ?? undefined,
            previewInteriorPhotoUrl: card.images.previewInteriorPhotoUrl ?? '',
          };

          return (
            <button
              key={card.id}
              className='snap-start -outline-offset-1'
              onClick={() => handleClick(selectedCard)}
            >
              {imageRenderer({
                src: card.images.previewMainPhotoUrl,
                alt: card.cardName,
                className: `object-fill object-center`,
                width: cardWidth,
                height: cardHeight,
                style: {
                  height: cardHeight,
                  width: cardWidth,
                },
              })}
            </button>
          );
        })}
        {handleCategoryClick ? (
          <Button
            className='!p-b-4 !p-i-6 text-center'
            onClick={handleCategoryClick}
            style={{
              height: cardHeight,
              width: cardWidth,
            }}
          >
            Show all
          </Button>
        ) : (
          <ButtonAsLink
            className='!p-b-4 !p-i-6 text-center'
            href={href}
            NextLink={NextLink}
            style={{
              height: cardHeight,
              width: cardWidth,
            }}
          >
            Show all
          </ButtonAsLink>
        )}
      </div>
    </div>
  );
}
