import { useState } from 'react';
import { SVG } from '@inkd/ui';
import { AddonCategoriesQuery } from '@inkd/graphql';

interface CategoryFiltersProps {
  categoryFilters: AddonCategoriesQuery['categories'] | undefined;
  filteredCategoryId: string | undefined;
  updateCategoriesToQuery: (categoryId: string | undefined) => void;
}

export function CategoryFilters({
  categoryFilters,
  filteredCategoryId,
  updateCategoriesToQuery,
}: CategoryFiltersProps) {
  const [showAll, setShowAll] = useState(false);

  if (!categoryFilters) {
    return null;
  }

  return (
    <section
      className='m-bs-8 m-be-12 web:m-bs-6 web:m-be-8'
      aria-labelledby='category-filters-heading'
    >
      <p id='category-filters-heading' className='sr-only'>
        Filter by additional category
      </p>
      <div className='web:gap-2 flex flex-wrap items-center gap-6'>
        {categoryFilters.map(category => {
          if (!category) {
            return null;
          }
          return (
            <button
              key={category.id}
              className='p-i-8 p-b-3 web:p-i-4 web:p-b-2 border-tonal-60 text-ui-sm text-primary hover:bg-surface-secondary focus-visible:bg-surface-secondary data-[selected=true]:bg-surface-active data-[selected=true]:border-surface-active web:border-2 rounded-sm border-4 bg-white data-[show-all=false]:[&:nth-child(n+3)]:hidden'
              data-selected={filteredCategoryId === category.id}
              data-show-all={showAll}
              onClick={() =>
                updateCategoriesToQuery(
                  filteredCategoryId !== category.id ? category.id : undefined
                )
              }
            >
              {category.categoryName}
            </button>
          );
        })}

        {categoryFilters.length > 2 ? (
          <button
            className='p-i-8 p-b-3 border-tonal-60 text-ui-sm text-primary hover:bg-surface-secondary focus-visible:bg-surface-secondary web:p-i-4 web:p-b-2 web:border-2 flex items-center gap-x-2 rounded-sm border-4 bg-white'
            onClick={() => setShowAll(current => !current)}
          >
            <SVG
              name={showAll ? 'X' : 'Ellipsis'}
              className='web:w-4 web:h-4 h-8 w-8'
            />
            <span className={showAll ? '' : 'sr-only'}>
              {showAll ? 'Collapse all' : 'Show all'}
            </span>
          </button>
        ) : null}
      </div>
    </section>
  );
}
