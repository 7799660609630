import type { ComponentPropsWithoutRef } from 'react';
import { cx } from 'cva';
import { SVG } from '@inkd/ui';

interface WebInputProps extends ComponentPropsWithoutRef<'input'> {
  label: string;
  inputValue: number | string | undefined;
  errorMsg: string;
  /** Set this to a boolean value if you ignore HTML validation and show the error state for this component _only_ when this prop is set to `true`. Useful if you want to manually handle validation yourself with javascript, instead of the browser default validation (i.e. for email addresses) */
  isInvalid?: boolean;
}

export function WebInput({
  className,
  label,
  inputValue,
  errorMsg,
  isInvalid,
  ...inputProps
}: WebInputProps) {
  return (
    <label
      htmlFor={inputProps.id}
      className={cx('text-primary group', className)}
      data-invalid={isInvalid}
    >
      <p className='text-ui-sm text-tonal-60 mb-2 block'>{label}</p>
      <input
        className={cx(
          'rounded-tiny bg-surface-primary border-tonal-60 peer h-14 w-full border-2 p-4 text-left text-sm text-inherit disabled:cursor-not-allowed disabled:opacity-75',
          isInvalid !== undefined
            ? 'group-data-[invalid=true]:border-error group-data-[invalid=true]:mb-2'
            : 'invalid:border-error invalid:mb-2'
        )}
        defaultValue={inputValue}
        {...inputProps}
      />
      <span
        className={cx(
          'flex items-center gap-1 duration-200 peer-disabled:cursor-not-allowed peer-disabled:opacity-75 ',
          isInvalid !== undefined
            ? 'group-data-[invalid=false]:hidden group-data-[invalid=false]:scale-y-0 group-data-[invalid=false]:opacity-0'
            : 'peer-valid:hidden peer-valid:scale-y-0 peer-valid:opacity-0'
        )}
      >
        <SVG className='text-error h-5 w-5' name='Error' />
        <span className='text-primary text-sm text-inherit'>{errorMsg}</span>
      </span>
    </label>
  );
}
