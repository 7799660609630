import type { ComponentPropsWithoutRef } from 'react';
import { cx } from 'cva';

export interface BadgeProps
  extends Omit<ComponentPropsWithoutRef<'span'>, 'aria-label'> {
  // enforce *required* aria-label with Typescript
  'aria-label': string;
}

export function Badge({ className, children, ...props }: BadgeProps) {
  return (
    <span
      className={cx(
        'text-primary bg-gradient-purple-to-yellow-clipped text-ui-tiny web:border-11 web:min-w-[1.5rem] web:min-h-[1.5rem] web:border-[0.25rem] flex aspect-square min-h-[2.75rem] min-w-[2.75rem] items-center justify-center rounded-full border-[0.5rem] border-transparent bg-origin-border font-semibold [background-clip:padding-box,border-box]',
        className
      )}
      {...props}
    >
      {children}
    </span>
  );
}
