import type { ComponentPropsWithoutRef } from 'react';

export interface LogoProps
  extends Omit<ComponentPropsWithoutRef<'div'>, 'children' | 'aria-label'> {
  'aria-label': string;
}

/**
 * Renders a `div` with `role="img"` with the appropriate SVG logo depending on whether it's being
 * loaded on a kiosk or on the web. The component automatically handles figuring out which logo to
 * load via the `data-rendering-on` attribute bound to the `html` element. Defaults to the styles
 * for the kiosk's logo
 */
export function Logo({
  'aria-label': ariaLabel,
  role = 'img',
  ...props
}: LogoProps) {
  return (
    <div
      className='web:inkd-logo-web inkd-logo-kiosk'
      aria-label={ariaLabel}
      role={role}
      {...props}
    />
  );
}
