import { type ShoppingCart } from '@inkd/xstate';

/**
 * Reducer function that calculates the total number of physical cards to be
 * printed that are currently in the cart
 */
export function getCartCardCount(
  cart: ShoppingCart,
  includeMarkedForDeletion: boolean = false
): number {
  return cart.reduce((cardCount, cartItem) => {
    // if we have an invalid quantity, don't count it in the running total
    if (typeof cartItem.quantity !== 'number') {
      return cardCount;
    }
    // if we are about to delete the card, don't count it in the running total
    if (cartItem.markedForDeletion && !includeMarkedForDeletion) {
      return cardCount;
    }
    // add the current line item to the running total
    return cardCount + cartItem.quantity;
  }, 0);
}
