import { SVG } from '@inkd/ui';
import { useMemo } from 'react';
import { type KioskContext } from '@inkd/xstate';

export const kioskCheckoutSteps = [
  'Cart',
  'Payment',
  'Printing',
  'Receipt',
  'Pickup',
] as const;

export const kioskOrderPickupSteps = ['Code', 'Printing', 'Pickup'] as const;

interface KioskProgressProps {
  currentStep: (typeof kioskCheckoutSteps)[number];
  orderType: Extract<KioskContext['orderType'], 'kiosk'>;
}
interface MobileProgressProps {
  currentStep: (typeof kioskOrderPickupSteps)[number];
  orderType: Extract<KioskContext['orderType'], 'mobile'>;
}

export function ProgressKiosk({
  currentStep,
  orderType,
}: KioskProgressProps | MobileProgressProps) {
  const steps =
    orderType === 'kiosk' ? kioskCheckoutSteps : kioskOrderPickupSteps;
  const currentStepIndex = useMemo(
    () => steps.findIndex(step => step === currentStep),
    [currentStep, steps]
  );

  return (
    <section
      aria-label='Checkout progress indicator'
      className='p-i-[4.25rem] p-b-[3.25rem] shadow-far1 rounded-[7rem] bg-white'
    >
      <div className='relative grid auto-cols-max grid-flow-col gap-x-4'>
        <hr className='text-tonal-30 absolute left-24 right-24 top-[calc(1rem-1px)] border border-current' />
        {steps.map((step, stepIndex) => (
          <div
            key={step}
            data-step={currentStep === step}
            className='data-[step=true]:text-text-primary text-text-secondary flex min-w-[10rem] flex-col items-center gap-y-2 text-sm data-[step=true]:font-bold'
          >
            <span
              data-step={currentStep === step}
              className='data-[step=true]:bg-text-primary data-[step=true]:border-text-primary bg-tonal-30 border-tonal-30 relative h-8 w-8 rounded-[1.25rem] border-2'
            >
              {currentStepIndex > stepIndex ? (
                <SVG
                  aria-hidden='true'
                  className='text-text-primary absolute left-1/2 top-1/2 z-10 h-6 w-6 -translate-x-1/2 -translate-y-1/2 rounded-[inherit]'
                  name='Check'
                />
              ) : null}
            </span>
            <p className='max-w-[10ch] text-center'>{step}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export const webCheckoutSteps = [
  'Cart',
  'Delivery Method',
  'Subscribe',
  'Confirmation',
] as const;

export function ProgressWeb({
  currentStep,
}: {
  currentStep: (typeof webCheckoutSteps)[number];
}) {
  return (
    <section
      aria-describedby='checkout-current-step'
      className='grid w-full place-items-center bg-white p-6'
    >
      <div className='m-be-5 relative grid auto-cols-max grid-flow-col gap-x-4'>
        <hr className='text-tonal-30 absolute left-2 right-2 top-1' />
        {webCheckoutSteps.map(step => (
          <span
            key={step}
            data-step={step === currentStep}
            className='data-[step=true]:bg-text-primary data-[step=true]:border-text-primary bg-tonal-30 border-tonal-30 z-10 h-2 w-2 rounded-full border-2'
          />
        ))}
      </div>
      <h2 className='text-ui-tiny font-medium uppercase'>Checkout</h2>
      <h1 id='checkout-current-step' className='text-heading1'>
        {currentStep}
      </h1>
    </section>
  );
}
