import { type NextLink } from '@inkd/web/src/components/NextTypes';
import type { ComponentPropsWithoutRef } from 'react';
import { type VariantProps } from 'cva';
import { SVG } from '@inkd/ui';
import type { GeneratedSVGNames } from '@inkd/svgs';
import { button } from './Button';

export interface ButtonAsLinkProps
  extends Omit<ComponentPropsWithoutRef<'a'>, 'href'>,
    VariantProps<typeof button> {
  href: string;
  NextLink: NextLink;
  icon?: GeneratedSVGNames;
}

/**
 * Component to use our brand button styles as an `<a>` element. This component will render a
 * flex container that puts any `children` as a direct sibling to an `icon`.
 */
export function ButtonAsLink({
  className = '',
  size = 'md',
  variant = 'primary',
  NextLink,
  icon,
  children,
  ...props
}: ButtonAsLinkProps) {
  return (
    <NextLink
      className={button({
        className:
          icon === 'ArrowLeft' ? `flex-row-reverse ${className}` : className,
        size,
        variant,
      })}
      data-variant={variant}
      data-size={size}
      {...props}
    >
      {children}
      {icon ? <SVG name={icon} /> : null}
    </NextLink>
  );
}
