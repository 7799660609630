import type { ComponentPropsWithoutRef } from 'react';
import { cx } from 'cva';
import { SVG } from '@inkd/ui';

interface InputProps
  extends Omit<ComponentPropsWithoutRef<'input'>, 'children'> {
  label: string;
  errorMsg?: string;
}

export function Input({
  className,
  type = 'text',
  label,
  errorMsg,
  ...inputProps
}: InputProps) {
  return (
    <label className={cx('text-primary', className)}>
      <p className='text-ui-sm text-tonal-60 mb-2 block'>{label}</p>
      <input
        type={type}
        className={`rounded-tiny p-i-8 p-b-6 peer border-4 text-sm text-inherit disabled:cursor-not-allowed disabled:opacity-75 ${
          errorMsg ? 'border-error mb-2' : 'border-tonal-60'
        }`}
        {...inputProps}
      />
      <span
        className={`flex items-center gap-2 duration-200 peer-disabled:cursor-not-allowed peer-disabled:opacity-75 ${
          errorMsg === undefined ? 'hidden scale-y-0 opacity-0' : ''
        }`}
        aria-hidden={errorMsg === undefined}
      >
        <SVG className='text-error h-10 w-10' name='Error' />
        <span className='text-sm text-inherit'>{errorMsg}</span>
      </span>
    </label>
  );
}
