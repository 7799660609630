/* eslint-disable react-hooks/rules-of-hooks */
import {
  useState,
  useEffect,
  type ComponentPropsWithoutRef,
  useCallback,
} from 'react';
import { createPortal } from 'react-dom';
import { cx } from 'cva';
import { type ModalProps, useModal } from '@inkd/ui';

export interface StillThereModalProps
  extends ComponentPropsWithoutRef<'dialog'>,
    Pick<ModalProps, 'handleCancel'> {
  handleConfirm: () => void;
}

/**
 * The modal that is always mounted in the Kiosk app to handle the Session Timeout event from
 * XState. It will prompt the user with "Are you still there?" and auto-start a new
 * purchasing session if the user does not confirm that they are still there before the
 * timeout ends.
 */
export function useStillThereModal() {
  const { modalRef, Modal } = useModal();

  const StillThereModal = useCallback(
    ({
      id = 'inkd-still-there-modal',
      className,
      handleCancel,
      handleConfirm,
      ...dialogProps
    }: StillThereModalProps) => {
      const [seconds, setSeconds] = useState<number>(59);

      const startTimer = useCallback(() => {
        const timer = setInterval(() => {
          setSeconds(prev => (prev > 0 ? prev - 1 : 59));
        }, 1000);
        return timer;
      }, []);

      const resetTimer = useCallback(() => {
        setSeconds(59);
      }, []);

      useEffect(() => {
        const timer = startTimer();
        return () => clearInterval(timer);
      }, [startTimer]);

      useEffect(() => {
        if (modalRef?.current?.open) {
          resetTimer();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [modalRef?.current?.open, resetTimer]);

      return createPortal(
        <Modal
          id={id}
          className={cx(
            'backdrop:bg-gradient-blue-to-yellow fixed inset-0 z-50 backdrop:z-50 backdrop:opacity-90',
            className
          )}
          headingText='Are you still there?'
          handleCancel={handleCancel}
          handleConfirm={(e, closeModal) => {
            e.preventDefault();
            closeModal();
            handleConfirm();
          }}
          cancelButtonText='New User'
          confirmButtonText="I'm still here!"
          closeButtonHandler='handleConfirm'
          {...dialogProps}
        >
          <p className='text-body text-center'>
            Your session will end in{' '}
            <span className='font-bold'>{seconds}</span> seconds due to
            inactivity.
          </p>
        </Modal>,
        document.body
      );
    },
    [Modal, modalRef]
  );

  return {
    modalRef,
    StillThereModal,
  };
}
