import type { ComponentPropsWithoutRef } from 'react';
import { cx } from 'cva';
import { SVG } from '@inkd/ui';

interface CheckboxProps
  extends Omit<ComponentPropsWithoutRef<'input'>, 'children'> {
  label: string;
}

export function Checkbox({
  className,
  label,
  ...checkboxProps
}: CheckboxProps) {
  return (
    <label
      htmlFor={checkboxProps.id}
      className={cx(
        'text-primary web:gap-x-3 grid select-none grid-cols-[auto_auto] gap-x-6 gap-y-2',
        className
      )}
    >
      <input type='checkbox' className='peer sr-only' {...checkboxProps} />
      <SVG
        name='Check'
        className='rounded-tiny border-primary peer-checked:bg-primary peer-focus-visible:outline-primary web:h-6 web:w-6 web:border-2 web:text-body h-10 w-10 border-4 bg-white text-white peer-focus-visible:outline peer-focus-visible:outline-offset-2 peer-disabled:cursor-not-allowed peer-disabled:opacity-75'
      />
      <span className='text-body peer-disabled:cursor-not-allowed peer-disabled:opacity-75'>
        {label}
      </span>
      <p className='col-span-full row-start-2 row-end-3 hidden items-center gap-2 peer-required:flex peer-disabled:opacity-75'>
        <SVG name='Error' className='text-error h-6 w-6' />
        <span className='text-ui-tiny'>This field is required.</span>
      </p>
    </label>
  );
}
