import type { ComponentPropsWithoutRef } from 'react';
import { cva, type VariantProps } from 'cva';
import { SVG } from '@inkd/ui';
import type { GeneratedSVGNames } from '@inkd/svgs';

export const button = cva(
  [
    'text-primary',
    'font-medium',
    'disabled:opacity-60',
    'disabled:cursor-not-allowed',
    'flex',
    'items-center',
    'gap-x-4',
    'web:gap-x-2',
  ],
  {
    variants: {
      variant: {
        primary: [
          'bg-gradient-purple-to-yellow-clipped',
          'border-[1rem]',
          'border-transparent',
          'bg-origin-border',
          '[background-clip:padding-box,border-box]',
          'relative',
          'after:absolute',
          'after:content-[""]',
          'after:-inset-4',
          'after:border-[0.25rem]',
          'after:border-primary/60',
          'web:border-[0.5rem]',
          'web:after:-inset-2',
          'web:after:border-[0.15rem]',
        ],
        secondary: [
          'bg-white',
          'border-tonal-60',
          'border-[0.25rem]',
          'web:border-[0.15rem]',
        ],
      },
      size: {
        lg: [
          'text-ui-lg',
          'rounded-lg',
          '[&_svg]:h-16',
          '[&_svg]:w-16',
          'web:rounded-[0.75rem]',
          'web:text-ui-md',
          'web:[&_svg]:h-7',
          'web:[&_svg]:w-7',
        ],
        md: [
          'text-ui-md',
          'rounded-md',
          '[&_svg]:h-12',
          '[&_svg]:w-12',
          'web:text-ui-sm',
          'web:rounded-[0.75rem]',
          'web:[&_svg]:h-6',
          'web:[&_svg]:w-6',
        ],
        sm: [
          'text-ui-sm',
          'rounded-sm',
          '[&_svg]:h-10',
          '[&_svg]:w-10',
          'web:rounded-[0.375rem]',
          'web:[&_svg]:h-4',
          'web:[&_svg]:w-4',
        ],
      },
    },
    compoundVariants: [
      // the paddings for our primary buttons need to be *less* than what each size shows above,
      // because we've changed the border utility used in the secondary variant (0.25rem kiosk,
      // 0.15rem web) to a different value for the primary variant (1rem kiosk, 0.5rem web), which
      // is a difference of 0.75rem on the kiosk and 0.35rem on the web. this makes up for the size
      // difference to keep our primary buttons the same size as the secondary button
      {
        variant: 'primary',
        size: 'lg',
        class: [
          'p-[5.25rem]',
          'after:rounded-lg',
          'web:after:rounded-[0.75rem]',
          'web:p-b-[0.65rem]',
          'web:p-i-[1.65rem]',
        ],
      },
      {
        variant: 'primary',
        size: 'md',
        class: [
          'p-[3.75rem]',
          'after:rounded-md',
          'web:after:rounded-[0.75rem]',
          'web:p-b-[0.65rem]',
          'web:p-i-[1.15rem]',
        ],
      },
      {
        variant: 'primary',
        size: 'sm',
        class: [
          'p-[2.25rem]',
          'after:rounded-sm',
          'web:after:rounded-[0.375rem]',
          'web:p-b-[0.15rem]',
          'web:p-i-[0.65rem]',
        ],
      },
      // set the default button padding sizes for the `secondary` variant
      {
        variant: 'secondary',
        size: 'lg',
        class: ['p-[6rem]', 'web:p-b-[1rem]', 'web:p-i-[2rem]'],
      },
      {
        variant: 'secondary',
        size: 'md',
        class: ['p-[4.5rem]', 'web:p-[1rem]'],
      },
      {
        variant: 'secondary',
        size: 'sm',
        class: ['p-[3rem]', 'web:p-b-[0.5rem]', 'web:p-i-[1rem]'],
      },
    ],
    defaultVariants: {
      variant: 'primary',
      size: 'md',
    },
  }
);

export interface ButtonProps
  extends ComponentPropsWithoutRef<'button'>,
    VariantProps<typeof button> {
  icon?: GeneratedSVGNames;
  loading?: boolean;
}

/**
 * Component to use our brand button styles as a `<button>` element. This component will render a flex container that puts any `children` as a direct sibling to an `icon` (or a loading indicator SVG, if `loading` is set to `true`).
 */
export function Button({
  className = '',
  size = 'md',
  variant = 'primary',
  children,
  icon,
  loading,
  disabled,
  ...props
}: ButtonProps) {
  return (
    <button
      className={button({
        className:
          icon === 'ArrowLeft'
            ? `flex-row-reverse items-center ${className}`
            : className,
        size,
        variant,
      })}
      data-variant={variant}
      data-size={size}
      disabled={loading === true || disabled === true}
      {...props}
    >
      {children}
      {loading ? (
        <SVG name='Spinner' className='animate-spinner [&>*]:animate-dash' />
      ) : icon ? (
        <SVG name={icon} />
      ) : null}
    </button>
  );
}
