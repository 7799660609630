import { useCallback, useRef } from 'react';
import type { ComponentPropsWithoutRef, MouseEvent, ReactNode } from 'react';
import { Button, type ButtonProps, SVG } from '@inkd/ui';
import { cx } from 'cva';

interface BaseModalProps
  extends Omit<ComponentPropsWithoutRef<'dialog'>, 'id'> {
  /** require an id for the modal, so that we can trigger it with buttons elsewhere in the app */
  id: string;
  /** className prop for the wrapper around the content provided as children to the Modal component */
  contentClassName?: string;
  handleCancel?: (clickEvent: MouseEvent<HTMLButtonElement>) => void;
  /**
   * Function to handle the onClick event for the Confirm button in the modal
   * @param clickEvent the click event handler for the Confirm button
   * @param closeModal a callback function that will trigger the Modal <dialog>'s `.close()` method
   */
  handleConfirm: (
    clickEvent: MouseEvent<HTMLButtonElement>,
    closeModal: () => void
  ) => void;
  /** valid <button> props that can be passed to the Cancel button */
  cancelButtonProps?: Omit<ButtonProps, 'children'>;
  /** valid <button> props that can be passed to the Confirm button */
  confirmButtonProps?: Omit<ButtonProps, 'children'>;
  /** the text to be shown in the Cancel button (defaults to "Cancel") */
  cancelButtonText?: string;
  /** the text to be shown in the Confirm button (defaults to "Confirm") */
  confirmButtonText?: string;
  /** prop that will allow the "X" close button of the modal to be passed either the handleCancel or
   * handleConfirm event handler function to trigger when clicked */
  closeButtonHandler?: 'handleCancel' | 'handleConfirm';
}

type ModalHeading =
  | {
      /** Text to be displayed as the <h2> tag for the Modal */
      headingText: string;
      headingComponent?: never;
    }
  | {
      /** The component to be rendered in the "heading" slot at the top of the modal */
      headingComponent: ReactNode;
      headingText?: never;
    };

export type ModalProps = BaseModalProps & ModalHeading;

/**
 * A hook that builds and returns a ref to and a primitive Modal component to be consumed
 * by the various modals throughout the application.
 */
export function useModal() {
  const modalRef = useRef<HTMLDialogElement>(null);

  /** A primitive Modal component to be extended in a specific implementation */
  const Modal = useCallback(
    ({
      id,
      className,
      contentClassName,
      children,
      headingText,
      headingComponent,
      cancelButtonProps = {},
      confirmButtonProps = {},
      handleCancel,
      handleConfirm,
      cancelButtonText = 'Cancel',
      confirmButtonText = 'Confirm',
      closeButtonHandler = 'handleCancel',
      ...dialogProps
    }: ModalProps) => {
      /** The id for the <h2> tag in the modal */
      const h2Id = `${id}-heading`;

      /** wrapper function around the `handleConfirm` prop that passes down the current event and a function that closes this modal */
      function handleConfirmCallback(e: MouseEvent<HTMLButtonElement>) {
        handleConfirm(e, () => modalRef.current?.close());
      }

      return (
        <dialog
          id={id}
          aria-labelledby={h2Id}
          className={cx(
            'shadow-far2 text-primary animate-scaleTo100 min-w-min rounded-lg bg-white',
            className
          )}
          ref={modalRef}
          {...dialogProps}
        >
          <form
            method='dialog'
            className='p-i-16 p-b-20 web:p-i-3 web:p-b-5 relative'
          >
            <button
              className='text-primary inset-ie-8 inset-bs-8 web:inset-ie-3 web:inset-bs-3 absolute'
              onClick={
                closeButtonHandler === 'handleCancel'
                  ? handleCancel
                  : handleConfirmCallback
              }
            >
              <span className='sr-only'>Close modal</span>
              <SVG name='X' className='web:w-6 web:h-6 h-12 w-12' />
            </button>
            {headingText ? (
              <div className='web:gap-4 flex flex-col gap-8'>
                <h2
                  id={h2Id}
                  className='text-heading1 web:m-bs-4 text-center font-light'
                >
                  {headingText}
                </h2>
                <hr className='text-tonal-30 m-i-auto w-32 border-2 border-current' />
              </div>
            ) : null}
            {headingComponent ? <div id={h2Id}>{headingComponent}</div> : null}
            <div
              className={cx(
                'text-body p-b-16 web:p-b-6 align-items-center grid',
                contentClassName
              )}
            >
              {children}
            </div>
            <div className='flex items-center justify-between gap-x-4'>
              <Button
                variant='secondary'
                {...cancelButtonProps}
                onClick={handleCancel}
              >
                {cancelButtonText}
              </Button>
              <Button
                type='button'
                {...confirmButtonProps}
                onClick={handleConfirmCallback}
              >
                {confirmButtonText}
              </Button>
            </div>
          </form>
        </dialog>
      );
    },
    []
  );

  return {
    modalRef,
    Modal,
  };
}
