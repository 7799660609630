import { z } from 'zod';

/** use zod to confirm the `input` is in fact a valid number (optionally provide a min and a max) */
export const isValidNumber = ({
  input,
  min,
  max,
}: {
  input: string;
  min?: number;
  max?: number;
}) => {
  return z
    .number()
    .min(min ?? 0)
    .max(max ?? Number.MAX_SAFE_INTEGER)
    .safeParse(parseFloat(input)).success;
};
