export interface PersonalizationFlowHeaderProps {
  imgSrc: string;
  headingText: string;
  viewName: string;
}

export const PersonalizationFlowHeader = ({
  imgSrc,
  headingText,
  viewName,
}: PersonalizationFlowHeaderProps) => {
  return (
    <>
      <div className='kiosk:gap-10 flex gap-2 self-start'>
        <div className='kiosk:w-52 relative'>
          <img
            alt='Card cover preview'
            src={imgSrc}
            className='bg-tonal-30 border-image-outline web:max-h-[42px] kiosk:w-52 kiosk:absolute kiosk:bottom-6 aspect-[5/7] border border-opacity-10'
          />
        </div>
        <div className='relative'>
          <h1 className='text-primary kiosk:text-[20px] text-[10px] font-medium uppercase leading-none'>
            {headingText}
          </h1>
          <h2 className='kiosk:text-heading1 text-primary text-[28px] font-light'>
            {viewName}
          </h2>
        </div>
      </div>
    </>
  );
};
