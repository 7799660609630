import { type PersonalizationContext } from '@inkd/xstate/machines/kioskFlows/personalizationFlow';
import { isValidNumber } from '.';

/**
 * Utility function to determine whether or not we should disable the `Next` button in the personalization flow when we are in the `gift card.gift card options` state.
 *
 * @param stateMatcher - pass a reference to the relevant machine's `state.matches` function here
 * @param context - pass a reference to the relevant machine's context here
 * @returns `true` if we should disable the button, `false` if not
 */
export function isGiftCardOptionsStateInvalid(
  stateMatcher: (arg: string) => boolean,
  context: PersonalizationContext
) {
  const {
    giftCardPrice,
    giftCardDenominationRange,
    giftCardDenominationType,
    // giftCardEmail,
  } = context;

  // first ensure that we are in one of the correct states to be disabling the `Next` button
  if (
    stateMatcher('gift card.gift card options') ||
    stateMatcher('gift card.edit gift card price') ||
    stateMatcher('gift card.edit gift card email')
  ) {
    // then check if we have a `Variable` denomination input and that we have a denomination range that is valid, before we verify if we have a valid number within that range currently entered. If we are missing any of those constraints, we should disable the button
    if (
      giftCardDenominationType === 'Variable' &&
      giftCardDenominationRange !== undefined &&
      giftCardDenominationRange.length === 2 &&
      !isValidNumber({
        // if `giftCardPrice` is `undefined`, pass an empty string so that our `isValidNumber` check fails
        input: giftCardPrice ? giftCardPrice.toString() : '',
        min: giftCardDenominationRange[0],
        max: giftCardDenominationRange[1],
      })
    ) {
      return true;
    } // whether or not we are on a `Variable` or `Fixed` denomination type, we should always disable the button if we have no email entered or an invalid email entered
    // else if (giftCardEmail === undefined || !isValidEmail(giftCardEmail)) {
    //   return true;
    // }
  }

  // otherwise, we should be good to move on to the next step and not disable the button!
  return false;
}
