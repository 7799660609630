import { cva } from 'cva';

/** Utility to apply the appropriate tailwindcss utilities based on predefined variants
 *
 * **Variants:**
 *
 * ```typescript
 * style: 'Modern' | 'Classic' | 'Handwriting'
 * size: 'sm' | 'md' | 'lg'
 * ```
 */
export const messageClass = cva(
  ['w-full', 'whitespace-pre-line', 'resize-none', 'overflow-hidden'],
  {
    variants: {
      variant: {
        Modern: ['font-modern-message'],
        Classic: ['font-classic-message'],
        Handwriting: ['font-handwriting-message'],
      },
      // there aren't any utilities that apply across all sizes, but we need these here to establish the variant type
      size: {
        sm: '',
        md: '',
        lg: '',
      },
      usage: {
        cardEditor: '',
        finalPreview: '',
      },
    },
    compoundVariants: [
      { usage: 'cardEditor', variant: 'Modern', className: 'leading-[177%]' },
      {
        usage: 'cardEditor',
        variant: 'Classic',
        className: ['leading-[185%]', 'italic'],
      },
      {
        usage: 'cardEditor',
        variant: ['Modern', 'Classic'],
        size: 'lg',
        className: [
          'text-[min(1.625rem,_3.28vw)]',
          'tracking-[calc(min(1.625rem,_3.28vw)*0.04)]',
        ],
      },
      {
        usage: 'cardEditor',
        variant: ['Modern', 'Classic'],
        size: 'md',
        className: [
          'text-[min(1.22rem,_2.46vw)]',
          'tracking-[calc(min(1.22rem,_2.46vw)*0.04)]',
        ],
      },
      {
        usage: 'cardEditor',
        variant: ['Modern', 'Classic'],
        size: 'sm',
        className: [
          'text-[min(0.8125rem,_1.64vw)]',
          'tracking-[calc(min(0.8125rem,_1.64vw)*0.04)]',
        ],
      },
      {
        usage: 'cardEditor',
        variant: 'Handwriting',
        size: 'lg',
        className: [
          'text-[min(2.25rem,_4.545455vw)]',
          'leading-[min(2.25rem,_4.545455vw)*0.04]',
        ],
      },
      {
        usage: 'cardEditor',
        variant: 'Handwriting',
        size: 'md',
        className: [
          'text-[min(1.6875rem,_3.409091vw)]',
          'leading-[min(1.6875rem,_3.409091vw)*0.04]',
        ],
      },
      {
        usage: 'cardEditor',
        variant: 'Handwriting',
        size: 'sm',
        className: [
          'text-[min(1.125rem,_2.272727vw)]',
          'leading-[min(1.125rem,_2.272727vw)*0.04]',
        ],
      },
      /** Extended styles for the final preview: each are scaled down by 190/396 per the designs */
      {
        usage: 'finalPreview',
        variant: 'Modern',
        className: 'leading-[177%]',
      },
      {
        usage: 'finalPreview',
        variant: 'Classic',
        className: ['leading-[185%]', 'italic'],
      },
      {
        usage: 'finalPreview',
        variant: ['Modern', 'Classic'],
        size: 'lg',
        className: [
          'text-[min(0.77967rem,_1.57374vw)]',
          'tracking-[calc(min(0.77967rem,_1.57374vw)*0.04)]',
        ],
      },
      {
        usage: 'finalPreview',
        variant: ['Modern', 'Classic'],
        size: 'md',
        className: [
          'text-[min(0.58535rem,_1.1803vw)]',
          'tracking-[calc(min(0.58535rem,_1.1803vw)*0.04)]',
        ],
      },
      {
        usage: 'finalPreview',
        variant: ['Modern', 'Classic'],
        size: 'sm',
        className: [
          'text-[min(0.38984rem,_0.78687vw)]',
          'tracking-[calc(min(0.38984rem,_0.78687vw)*0.04)]',
        ],
      },
      {
        usage: 'finalPreview',
        variant: 'Handwriting',
        size: 'lg',
        className: [
          'text-[min(1.07955rem,_2.1809vw)]',
          'leading-[min(1.07955rem,_2.1809vw)*0.04]',
        ],
      },
      {
        usage: 'finalPreview',
        variant: 'Handwriting',
        size: 'md',
        className: [
          'text-[min(0.80966rem,_1.63567vw)]',
          'leading-[min(0.80966rem,_1.63567vw)*0.04]',
        ],
      },
      {
        usage: 'finalPreview',
        variant: 'Handwriting',
        size: 'sm',
        className: [
          'text-[min(0.53977rem,_1.09045vw)]',
          'leading-[min(0.53977rem,_1.09045vw)*0.04]',
        ],
      },
    ],
    defaultVariants: {
      usage: 'cardEditor',
      variant: 'Handwriting',
      size: 'lg',
    },
  }
);

/** Utility to apply the appropriate tailwindcss utilities based on predefined variants
 *
 * **Variants:**
 *
 * ```typescript
 * style: 'Modern' | 'Classic' | 'Handwriting'
 * ```
 */
export const signatureClass = cva(
  ['w-full', 'whitespace-pre-line', 'text-center', 'text-ellipsis'],
  {
    variants: {
      variant: {
        Modern: ['font-modern-signature'],
        Classic: ['font-classic-signature'],
        Handwriting: ['font-handwriting-signature'],
        Normal: ['font-sans'],
      },
      usage: {
        cardEditor: '',
        finalPreview: '',
      },
    },
    compoundVariants: [
      { usage: 'cardEditor', className: 'leading-[2.2rem]' },
      { usage: 'finalPreview', className: 'leading-[1.056rem]' },
      {
        usage: 'cardEditor',
        variant: ['Modern', 'Handwriting'],
        className: ['text-[min(1.25rem,_2.525253vw)]'],
      },
      {
        usage: 'cardEditor',
        variant: 'Classic',
        className: ['text-[min(1.6875rem,_3.409091vw)]'],
      },
      {
        usage: 'finalPreview',
        variant: ['Modern', 'Handwriting'],
        className: ['text-[min(0.59975rem,_1.21161vw)]'],
      },
      {
        usage: 'finalPreview',
        variant: 'Classic',
        className: ['text-[min(0.80966rem,_1.63567vw)]'],
      },
    ],
  }
);

export type NamedFontSize = 'sm' | 'md' | 'lg';

/** Calculates the font size variant based on the number of characters in the message string */
export const calcFontSizeVariant = (message?: string | null): NamedFontSize => {
  if (!message || message.length < 80) {
    return 'lg';
  }
  if (message.length < 160) {
    return 'md';
  }
  return 'sm';
};
