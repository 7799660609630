import useLocalStorageState from 'use-local-storage-state';
import type { ShoppingCart } from '@inkd/xstate';

/**
 * Custom hook to persist the shopping cart to localStorage. Thin wrapper around the
 * `useLocalStorageState` hook from the `use-local-storage-state` npm package.
 */
export function useCartState(defaultValue = []) {
  const [cart, updateCart, { removeItem: clearCart, isPersistent }] =
    useLocalStorageState<ShoppingCart>('cart', {
      defaultValue,
    });

  return {
    /** the current state of the cart in localStorage */
    cart,
    /** react setState dispatch function to update the cart in localStorage */
    updateCart,
    /** `false` if localStorage is throwing an error and data is only persisted in-memory */
    isPersistent,
    /** Clears the cart from localStorage */
    clearCart,
  };
}
