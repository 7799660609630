import { GiftCard } from '@inkd/ui/components/GiftCard';
import { SVG } from '@inkd/ui';
// import { KioskInput } from '@inkd/ui';
import { ReactElement } from 'react';

export interface KioskGiftCardTailorProps {
  /**
   * This element is responsible for allowing the user to view the range of, and select
   * their desired gift card denomination.
   *
   * At the time of writing this, the `ToggleButtonGroup` is the best option for this
   * (and can be used in conjunction with the `useToggleButtonGroup` hook).
   *
   * This component must be passed in as a ReactElement because the selected denomination
   * state must belong to the parent of the `KioskGiftCardTailor`.
   *
   * **example prop value:**
   * ```tsx
   * <ToggleButtonGroup
   *   headingText='Amount'
   *   name='denomination-selector'
   *   toggleButtons={toggleButtons}
   *   fullWidth={true}
   * />
   * ```
   */
  denominationSelector: ReactElement;
  selectedDenomination: string;
  providerName: string;
  imgSrc: string;
  email?: string;
  emailInputErrorMessage?: string;
  /** this function must remove the gift card from the personalization flow and return the user to the interior customization */
  removeGiftCard: () => void;
  /** this function needs to trigger a modal with a floating keyboard to collect the email */
  // collectEmail: () => void;
}

/**
 * This component prompts the user to select a gift card denomination, and
 * provide an email address (for retrieving the gift card information upon
 * order completion). It displays the current selected option in plain text,
 * and also provides a way to cancel the gift card addition process (indicated
 * with a trash can icon button).
 *
 * **Please note this component doesn't perform any business logic or input
 * validation**; these tasks are expected to take place in the parent component.
 *
 * ### Resources
 *
 * - [Design Reference](https://www.figma.com/file/eNHkTSgulGkScTzU1c9O3o/Layouts?type=design&node-id=910-4008&mode=dev)
 */
export const KioskGiftCardTailor = ({
  denominationSelector,
  selectedDenomination,
  providerName,
  imgSrc,
  // email,
  // emailInputErrorMessage,
  removeGiftCard,
}: // collectEmail,
KioskGiftCardTailorProps) => (
  <div className='bg-surface-secondary border-line relative flex select-none flex-col gap-12 rounded-lg border p-8'>
    <button
      onClick={removeGiftCard}
      className='border-tonal-60 bg-surface-secondary absolute -right-5 -top-5 flex h-14 w-14 items-center justify-center rounded-sm border-4'
    >
      <span className='sr-only'>Cancel gift card customization</span>
      <SVG name='Bin' className='h-8 w-8' />
    </button>
    <div className='flex w-full items-center gap-12'>
      <GiftCard providerName={providerName} imgSrc={imgSrc} />
      <p className='select-none whitespace-pre-line text-sm font-normal'>
        {`A `}
        <span className='font-semibold'>
          {selectedDenomination
            ? `$${selectedDenomination} ${providerName}`
            : providerName}
        </span>
        {/* {` gift card will be:\n - Printed on the back of the card\n - Sent to your email as a backup`} */}
        {` gift card will be:\n - Printed on the back of the card`}
      </p>
    </div>
    {
      /* this block is merely a reminder for the developer to pass in a
       * denomination selector; the component will gracefully degrade if missing */
      denominationSelector ?? (
        <div className='border-purple flex min-h-[130px] w-full items-center justify-center rounded-md border-4 border-dashed'>
          <p className='text-purple text-ui-sm'>
            warning: denomination selector not provided
          </p>
        </div>
      )
    }
    {/* <KioskInput
      label='Email address for backup'
      inputValue={email}
      openEditorModal={collectEmail}
      errorMsg={emailInputErrorMessage}
    /> */}
  </div>
);
