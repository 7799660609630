import type { ComponentPropsWithoutRef } from 'react';
import { cx } from 'cva';
import { SVG } from '@inkd/ui';

interface KioskInputProps
  extends Omit<ComponentPropsWithoutRef<'button'>, 'children'> {
  label: string;
  inputValue?: string;
  errorMsg?: string;
  openEditorModal: () => void;
}

export function KioskInput({
  className,
  label,
  inputValue,
  placeholder,
  errorMsg,
  openEditorModal,
  ...inputProps
}: KioskInputProps) {
  return (
    <label htmlFor={inputProps.id} className={cx('text-primary', className)}>
      <p className='text-ui-sm text-tonal-60 mb-2 block'>{label}</p>
      <button
        onClick={openEditorModal}
        className={`rounded-tiny bg-surface-primary p-i-8 p-b-6 peer min-h-[96px] w-full border-4 text-left text-sm text-inherit disabled:cursor-not-allowed disabled:opacity-75 ${
          errorMsg ? 'border-error mb-2' : 'border-tonal-60'
        }`}
        title='trigger on-screen keyboard to enter email'
        {...inputProps}
      >
        {inputValue ? inputValue : placeholder}
      </button>
      <span
        className={`flex items-center gap-2 duration-200 peer-disabled:cursor-not-allowed peer-disabled:opacity-75 ${
          errorMsg === undefined ? 'hidden scale-y-0 opacity-0' : ''
        }`}
        aria-hidden={errorMsg === undefined}
      >
        <SVG className='text-error h-10 w-10' name='Error' />
        <span className='text-primary text-sm text-inherit'>{errorMsg}</span>
      </span>
    </label>
  );
}
