import { GiftCard, GiftCardProps } from '../GiftCard/GiftCard';

export interface GiftCardSelectorProps {
  giftCards: GiftCardProps[];
  showAll: () => void;
}

/**
 * Render a grid of gift cards and a 'Show all' button
 * @giftCards array of gift cards; *length should be 11 items!*
 * @showAll the function that will toggle the modal showing all available gift cards
 */
export const GiftCardSelector = ({
  giftCards,
  showAll,
}: GiftCardSelectorProps) => {
  // if length of gift cards array is longer than 11, truncate to 11 items
  if (giftCards.length > 11) {
    giftCards = giftCards.slice(0, 11);
  }
  // if shorter, truncate to the nearest lower value that will fill the grid
  else if (giftCards.length < 11) {
    giftCards = giftCards.slice(
      0,
      giftCards.length - (giftCards.length % 3) - 1
    );
  }

  return (
    <div className='kiosk:gap-8 grid w-full grid-cols-3 grid-rows-4 gap-2 md:gap-4'>
      {giftCards.map(cardInfo => (
        <GiftCard {...cardInfo} key={cardInfo.providerName} />
      ))}
      <button
        onClick={showAll}
        aria-label='View entire card catalog'
        className='border-tonal-60 p-b-2 p-i-3 kiosk:p-b-8 kiosk:p-i-18 kiosk:text-ui-md text-ui-tiny md:text-ui-sm kiosk:rounded-md kiosk:border-4 flex aspect-[8/5] h-full w-full items-center justify-center rounded-sm border-2 bg-white'
      >
        Show all
      </button>
    </div>
  );
};
