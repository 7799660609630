import type { ComponentPropsWithoutRef, Dispatch, SetStateAction } from 'react';
import { useState } from 'react';

export interface ToggleButtonGroupProps<StringLikeType extends string>
  extends Omit<
    ComponentPropsWithoutRef<'fieldset'>,
    'name' | 'defaultChecked'
  > {
  /** A list of ToggleButtonProps objects to render */
  toggleButtons: ToggleButtonExternalProps<StringLikeType>[];
  /** The text to be shown as a heading within a `<legend>` tag inside the `<fieldset>` */
  headingText: string;
  /** The name should be unique, as it is how we are able to associate the radio buttons in this
   * group with each other and the group's containing `<fieldset>` */
  name: string;
  /** force the underlying container to let each button expand to fill the full width of the
   * container that you render this into */
  fullWidth?: boolean;
}

/**
 * React hook that returns a ToggleButtonGroup component, and the handler for a piece of React
 * state. The hook will automatically update that react state in an onChange event, unless you pass
 * a custom onChange handler to the radiogroup `<fieldset>`, in which you will need to manually
 * handle the state change yourself.
 */
export function useToggleButtonGroup<StringLikeType extends string>(
  defaultValue?: StringLikeType
) {
  const [selected, setSelected] = useState<StringLikeType | undefined>(
    defaultValue
  );

  return {
    selected,
    setSelected,
    ToggleButtonGroup({
      className,
      fullWidth = false,
      toggleButtons,
      headingText: heading,
      name,
      ...fieldsetProps
    }: ToggleButtonGroupProps<StringLikeType>) {
      return (
        <fieldset
          // The fieldset should have an ARIA role of `radiogroup` here since it is surrounding a
          // group of related radio buttons, even though the a11y linter flags this. Links below are
          // to the MDN docs and WAI-ARIA radio group component pattern docs confirming this
          //
          // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/roles/radiogroup_role#examples
          // https://www.w3.org/WAI/ARIA/apg/patterns/radio/
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
          role='radiogroup'
          name={name}
          className={className}
          {...fieldsetProps}
        >
          <legend className='text-ui-sm text-tonal-60 mb-2'>{heading}</legend>
          <div
            className={`border-tonal-60 bg-surface-primary [&>span:first-of-type]:-m-is-1 [&>span:last-of-type]:-m-ie-1 web:border-2 grid grid-flow-col rounded-sm border-4 ${
              fullWidth ? 'auto-cols-fr' : 'auto-cols-max'
            }`}
          >
            {toggleButtons.map(toggleBtn => (
              <ToggleButton
                data-cy={toggleBtn.name}
                key={toggleBtn.value}
                name={name}
                selected={selected}
                setSelected={setSelected}
                {...toggleBtn}
              />
            ))}
          </div>
        </fieldset>
      );
    },
  };
}

interface ToggleButtonExternalProps<StringLikeType extends string>
  extends Omit<ComponentPropsWithoutRef<'input'>, 'defaultChecked'> {
  label: string;
  value: StringLikeType;
}

export interface ToggleButtonInternalProps<StringLikeType extends string>
  extends ToggleButtonExternalProps<StringLikeType> {
  selected: StringLikeType | undefined;
  setSelected: Dispatch<SetStateAction<StringLikeType | undefined>>;
}

function ToggleButton<StringLikeType extends string>({
  id,
  name,
  label,
  value,
  className,
  selected,
  setSelected,
  ...radioProps
}: ToggleButtonInternalProps<StringLikeType>) {
  return (
    <span className={className}>
      <input
        type='radio'
        id={id}
        name={name}
        value={value}
        className='peer hidden'
        checked={selected === value}
        onChange={e => {
          setSelected(e.target.value as StringLikeType);
        }}
        {...radioProps}
      />
      <label
        htmlFor={id}
        className='p-i-10 web:p-i-2 peer-checked:border-tonal-60 peer-checked:bg-tonal-10 p-b-4 -m-b-1 web:-m-b-[2px] text-ui-sm web:border-2 relative grid place-items-center gap-3 rounded-sm border-4 border-transparent peer-disabled:cursor-not-allowed peer-disabled:opacity-75 peer-checked:[&>hr:last-of-type]:visible peer-disabled:[&>hr]:opacity-75'
      >
        <hr className='web:border invisible border-2' />
        {label}
        <hr className='border-tonal-60 web:border web:w-5 invisible w-10 border-2' />
      </label>
    </span>
  );
}
